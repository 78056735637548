/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/guide/browser-support
 */
import 'isomorphic-fetch';
import 'core-js';
// import 'core-js/es/array';
// import 'core-js/es/array-buffer';
// import 'core-js/es/data-view';
// import 'core-js/es/date';
// import 'core-js/es/function';
// import 'core-js/es/instance';
// import 'core-js/es/json';
// import 'core-js/es/map';
// import 'core-js/es/math';
// import 'core-js/es/number';
// import 'core-js/es/object';
// import 'core-js/es/promise';
// import 'core-js/es/reflect';
// import 'core-js/es/regexp';
// import 'core-js/es/set';
// import 'core-js/es/string';
// import 'core-js/es/symbol';
// import 'core-js/es/typed-array';
// import 'core-js/es/weak-map';
// import 'core-js/es/weak-set';
// // import 'core-js/es/parse-int';
// // import 'core-js/es/parse-float';

import 'mdn-polyfills/Array.from';
import 'mdn-polyfills/Array.of';
import 'mdn-polyfills/Array.prototype.fill';
import 'mdn-polyfills/Array.prototype.filter';
import 'mdn-polyfills/Array.prototype.find';
import 'mdn-polyfills/Array.prototype.findIndex';
import 'mdn-polyfills/Array.prototype.forEach';
import 'mdn-polyfills/Array.prototype.includes';
import 'mdn-polyfills/Array.prototype.reduce';
import 'mdn-polyfills/Array.prototype.some';
import 'mdn-polyfills/CustomEvent';
import 'mdn-polyfills/Element.prototype.classList';
import 'mdn-polyfills/Element.prototype.closest';
// import 'mdn-polyfills/Element.prototype.getAttributeNames';
import 'mdn-polyfills/Element.prototype.matches';
import 'mdn-polyfills/Element.prototype.toggleAttribute';
import 'mdn-polyfills/Function.prototype.bind';
import 'mdn-polyfills/HTMLCanvasElement.prototype.toBlob';
import 'mdn-polyfills/MouseEvent';
import 'mdn-polyfills/Node.prototype.addEventListener';
import 'mdn-polyfills/Node.prototype.after';
import 'mdn-polyfills/Node.prototype.append';
import 'mdn-polyfills/Node.prototype.before';
import 'mdn-polyfills/Node.prototype.children';
import 'mdn-polyfills/Node.prototype.firstElementChild';
import 'mdn-polyfills/Node.prototype.prepend';
import 'mdn-polyfills/Node.prototype.remove';
import 'mdn-polyfills/Node.prototype.replaceWith';
import 'mdn-polyfills/NodeList.prototype.forEach';
import 'mdn-polyfills/Number.isInteger';
import 'mdn-polyfills/Number.isNaN';
import 'mdn-polyfills/Object.assign';
import 'mdn-polyfills/Object.create';
import 'mdn-polyfills/Object.entries';
import 'mdn-polyfills/Object.keys';
import 'mdn-polyfills/Object.values';
import 'mdn-polyfills/String.prototype.endsWith';
import 'mdn-polyfills/String.prototype.includes';
import 'mdn-polyfills/String.prototype.padEnd';
import 'mdn-polyfills/String.prototype.padStart';
import 'mdn-polyfills/String.prototype.repeat';
import 'mdn-polyfills/String.prototype.startsWith';
import 'mdn-polyfills/String.prototype.trim';

// import 'mdn-polyfills/Object.assign';
// import 'mdn-polyfills/Object.create';
// import 'mdn-polyfills/Object.entries';
// import 'mdn-polyfills/Array.from';
// import 'mdn-polyfills/Array.of';
// import 'mdn-polyfills/Array.prototype.find';
// import 'mdn-polyfills/Array.prototype.forEach';
// import 'mdn-polyfills/Array.prototype.filter';
// import 'mdn-polyfills/Array.prototype.findIndex';
// import 'mdn-polyfills/Array.prototype.includes';
// import 'mdn-polyfills/String.prototype.includes';
// import 'mdn-polyfills/String.prototype.repeat';
// import 'mdn-polyfills/String.prototype.startsWith';
// import 'mdn-polyfills/String.prototype.endsWith';
// import 'mdn-polyfills/String.prototype.padStart';
// import 'mdn-polyfills/String.prototype.padEnd';
// import 'mdn-polyfills/Function.prototype.bind';
// import 'mdn-polyfills/NodeList.prototype.forEach';
// import 'mdn-polyfills/Element.prototype.closest';
// import 'mdn-polyfills/Element.prototype.matches';
// import 'mdn-polyfills/MouseEvent';
// import 'mdn-polyfills/CustomEvent';

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE9, IE10 and IE11 requires all of the following polyfills. **/
// import 'core-js/es6/symbol';
// import 'core-js/es6/object';
// import 'core-js/es6/function';
// import 'core-js/es6/parse-int';
// import 'core-js/es6/parse-float';
// import 'core-js/es6/number';
// import 'core-js/es6/math';
// import 'core-js/es6/string';
// import 'core-js/es6/date';
// import 'core-js/es6/array';
// import 'core-js/es6/regexp';
// import 'core-js/es6/map';
// import 'core-js/es6/weak-map';
// import 'core-js/es6/set';
// import 'core-js/es7/array';

/**
 * If the application will be indexed by Google Search, the following is required.
 * Googlebot uses a renderer based on Chrome 41.
 * https://developers.google.com/search/docs/guides/rendering
 **/

/** IE10 and IE11 requires the following for NgClass support on SVG elements */
import 'classlist.js'; // Run `npm install --save classlist.js`.

/** IE10 and IE11 requires the following for the Reflect API. */
// import 'core-js/es6/reflect';
// import 'core-js/es7/reflect';

/**
 * Web Animations `@angular/platform-browser/animations`
 * Only required if AnimationBuilder is used within the application and using IE/Edge or Safari.
 * Standard animation support in Angular DOES NOT require any polyfills (as of Angular 6.0).
 **/
import 'web-animations-js'; // Run `npm install --save web-animations-js`.

// import './zone-flags.ts';
/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 */

(window as any).__Zone_disable_customElements = true;
(window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
(window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
(window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames
(window as any).__Zone_enable_cross_context_check = true;

// (window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
// (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
// (window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames

/*
 * in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 * with the following flag, it will bypass `zone.js` patch for IE/Edge
 */
(window as any).__Zone_enable_cross_context_check = true;

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js/dist/zone'; // Included with Angular CLI.

/***************************************************************************************************
 * APPLICATION IMPORTS
 */
